
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        



















































.related-positions {
  @include fluid(
    margin-top,
    (
      s: 100px,
      xl: 200px,
    )
  );
  @include fluid(
    margin-bottom,
    (
      s: 80px,
      xl: 130px,
    )
  );

  position: relative;
  margin-top: 2rem;

  ::v-deep {
    [class*='action--'] {
      @include mq(m) {
        background-color: $c-white;
      }
    }
  }
}

.related-positions__title {
  @extend %fw-normal;

  color: $c-dark-blue;

  @include mq(m) {
    margin-left: 4rem;
  }
}

.related-positions-inner {
  margin-top: 2.4rem;

  @include mq(m) {
    margin-top: 3.3rem;
  }
}

.related-positions__item {
  @extend %shadow;

  position: relative;
  display: flex;
  align-items: center;

  @include mq($until: m) {
    align-items: flex-start;
    box-shadow: none;

    & + & {
      padding-top: 2.4rem;
      padding-bottom: 0;
      border-top: 1px solid $c-black-40;
    }
  }

  @include mq(m) {
    padding: 3rem col(1, 24);
    border-radius: 16px;
  }
}

.related-positions__item + .related-positions__item {
  margin-top: 2.4rem;

  @include mq(m) {
    margin-top: 2rem;
  }
}

.related-positions__item-container {
  display: flex;

  @include mq($until: m) {
    flex-direction: column;
    padding-top: 0.4rem;
  }

  @include mq(m) {
    align-items: center;
    flex: 1;
  }
}

.related-positions__item__category {
  margin-right: col(1, 22);
  flex: 0;

  &__link {
    @extend %tag;
    @extend %text-nodecoration;

    display: inline-flex;
    margin-right: 2rem;
    color: $c-white;
    background-color: $c-blue;
    border-color: $c-blue;

    @include mq(m) {
      margin-right: 0;
    }
  }
}

.related-positions__item__title {
  @extend %text-nodecoration;
  @include fluid(
    font-size,
    (
      s: 14px,
      xl: 16px,
    )
  );

  color: $c-dark-blue;

  @include mq($until: m) {
    margin-bottom: 1rem;
  }

  @include mq(m) {
    margin-right: col(1, 22);
    width: col(18, 22);
  }
}

.related-positions__item__date,
.related-positions__item__location {
  color: $c-black-60;
  font-size: 1.4rem;
}

.related-positions__filters {
  margin-bottom: 6rem;
  border-bottom: 1px solid $c-black-40;

  @include mq(m) {
    padding: 0 col(1, 24);
  }
}

.related-positions__filters__items {
  @extend %list-nostyle;

  display: flex;
}

.related-positions__filters__item {
  @extend %fw-semi-bold;

  padding: 0 2rem 3rem;
  color: $c-black;
  opacity: 0.5;
  text-decoration: none;
  border-bottom: 3px solid transparent;
  transition: all 0.3s ease-in-out;

  &.is-active,
  &:hover {
    color: $c-dark-blue;
    opacity: 1;
    border-bottom: 3px solid $c-yellow;
  }
}

.related-positions__item__link {
  @include get-all-space;
}
