
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





















































































.career-single__content {
  max-width: 72rem;
  margin: auto;

  @include mq($until: m) {
    padding-right: col(1, 24);
    padding-left: col(1, 24);
  }
}

.career-single__title {
  @include fluid(
    font-size,
    (
      s: 20px,
      xl: 28px,
    )
  );

  @extend %fw-normal;

  margin-bottom: 3rem;

  line-height: 9rem;
  letter-spacing: 1px;
}

// Transitions

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.5s;
}

.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
  transform: translateY(40px);
}
